import * as React from 'react';
import { LoginFormContainer } from '../../../containers/LoginForm';
import { WithFooterLayout } from '../../Layout/WithFooterLayout';

const LoginPage = () => (
  <WithFooterLayout>
    <LoginFormContainer />
  </WithFooterLayout>
);

export { LoginPage };
