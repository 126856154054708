import { LoginForm } from '../../components/LoginForm';
import { connect } from 'react-redux';
import { State, Dispatch } from '../../store/types';
import { login } from '../../store/operations/login';

const mapStateToProps = (state: State) => ({
  errorMessages: state.loginForm.errorMessages,
  isSubmitting: state.loginForm.isLoading,
});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSubmit: (accountName: string, password: string) => {
    dispatch(login(accountName, password));
  },
});

export const LoginFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(LoginForm);
