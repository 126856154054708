import Router from 'next/router';

import { extractErrorMessage } from '../../httpClients/formatter';
import { getActionsApiClient } from '../../httpClients/ownerApiClient';
import { captureException } from '../../logger/logger';
import { DEFAULT_ERROR_MESSAGE } from '../../resources/messages/error';
import { loginForm } from '../modules/loginForm';
import { ThunkAction } from '../types';

export const login =
  (emailAddress: string, password: string): ThunkAction<void> =>
  async (dispatch) => {
    dispatch(loginForm.actions.login());

    const ActionsApiClient = getActionsApiClient();

    try {
      const loginActionResult = await ActionsApiClient.loginAction({
        loginActionRequest: {
          email_address: emailAddress,
          password,
        },
      });

      const {
        member_id: userId,
        name: name = '',
        service_type: serviceType,
      } = loginActionResult;

      if (serviceType === undefined) {
        dispatch(
          loginForm.actions.loginFailed({
            messages: [DEFAULT_ERROR_MESSAGE],
          }),
        );
        return;
      }

      dispatch(loginForm.actions.loginSuccess({ userId, name, serviceType }));
    } catch (e) {
      if (e instanceof Response) {
        const json = await e.clone().json();
        // ログイン済み
        if (json.code === 'OWNER20002') {
          dispatch(
            loginForm.actions.loginFailed({
              messages: await extractErrorMessage(e),
            }),
          );
          await Router.push('/workspace');
          return;
        }
      }

      if (e instanceof Error) {
        captureException(e);
      }

      dispatch(
        loginForm.actions.loginFailed({
          messages: await extractErrorMessage(e as Response),
        }),
      );
    }
  };
