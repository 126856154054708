import * as React from 'react';
import {
  TextField,
  Button,
  Typography,
  Grid,
  SnackBar,
  TextLink,
} from '@stafes/gochikuru-now-ui';
import { CustomCard } from '../CustomCard';
import Link from 'next/link';
import { FormLogo } from '../FormLogo';
import styled from 'styled-components';
import { useState } from 'react';
import { routes } from '../../config/routes';

const TitleGrid = styled(Grid)`
  margin: ${(props) => props.theme.spacing(2)}px;
`;

const TextFieldGrid = styled(Grid)`
  margin: ${(props) => props.theme.spacing(2)}px
    ${(props) => props.theme.spacing(10)}px;
`;

const SnackBarContainer = styled.div`
  margin: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(8)}px;
`;

const ErrorTypography = styled(Typography)`
  color: ${(props) => props.theme.colors.red};
  margin-top: ${(props) => props.theme.spacing(1)}px;
`;

const ButtonGrid = styled(Grid)`
  margin: ${(props) => props.theme.spacing(6)}px;
`;

const LinkGrid = styled(Grid)`
  margin: ${(props) => props.theme.spacing(2)}px;
`;

interface Props {
  onSubmit: (emailAddress: string, password: string) => void;
  isSubmitting: boolean;
  errorMessages: string[];
}

const LoginForm: React.FC<Props> = ({
  errorMessages,
  isSubmitting,
  onSubmit,
}) => {
  const [emailAddress, setEmailAddress] = useState<string>('');
  const [emailAddressError, setEmailAddressError] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');

  const handleOnChangeEmailAddress = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setEmailAddress(event.target.value);
    setEmailAddressError('');
  };

  const handleOnChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    event.preventDefault();
    setPassword(event.target.value);
    setPasswordError('');
  };

  const handleOnSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    let isError = false;
    if (emailAddress === '') {
      isError = true;
      setEmailAddressError('メールアドレスが未入力です');
    }

    if (password === '') {
      isError = true;
      setPasswordError('パスワードが未入力です');
    }

    if (!isError) {
      onSubmit(emailAddress, password);
    }
  };

  return (
    <CustomCard useBoxShadow={true}>
      <FormLogo />
      <TitleGrid justifyContent="center">
        <Typography variant="h2">管理者ページログイン</Typography>
      </TitleGrid>
      {errorMessages.length > 0 &&
        errorMessages.map((message, index) => (
          <SnackBarContainer key={index}>
            <SnackBar key={index}>{message}</SnackBar>
          </SnackBarContainer>
        ))}
      <Grid flexDirection="column">
        <TextFieldGrid flexDirection="column">
          <TextField
            onChange={handleOnChangeEmailAddress}
            placeholder="メールアドレス"
            isError={emailAddressError !== ''}
          />
          {emailAddressError !== '' && (
            <ErrorTypography variant="body">
              {emailAddressError}
            </ErrorTypography>
          )}
        </TextFieldGrid>
        <TextFieldGrid flexDirection="column">
          <TextField
            onChange={handleOnChangePassword}
            type="password"
            placeholder="パスワード"
            isError={passwordError !== ''}
          />
          {passwordError !== '' && (
            <ErrorTypography variant="body">{passwordError}</ErrorTypography>
          )}
        </TextFieldGrid>
        <ButtonGrid justifyContent="center">
          <Button
            text="ログイン"
            onClick={handleOnSubmit}
            variant="form_primary"
            loading={isSubmitting}
          />
        </ButtonGrid>
        <LinkGrid justifyContent="center">
          <Link href={routes.resetPasswordRequest} passHref>
            <TextLink text="パスワードを忘れた方はこちら" />
          </Link>
        </LinkGrid>
      </Grid>
    </CustomCard>
  );
};

export { LoginForm };
