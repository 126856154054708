import React from 'react';
import { NonAuthPageBaseContainer } from '../containers/NonAuthPageBase';
import { LoginPage } from '../components/Pages/LoginPage';
import { Head } from '../components/Head/Head';

const Login = () => (
  <React.Fragment>
    <Head title="ログインページ" noindex />

    <NonAuthPageBaseContainer>
      <LoginPage />
    </NonAuthPageBaseContainer>
  </React.Fragment>
);

export default Login;
